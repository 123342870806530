import React from "react";
import { useTranslation } from "react-i18next";
import {
  DIGITAL_KISS_LOGO,
  GOOGLE_MAPS_LINK,
  SOCIAL_MEDIA,
  WEBSITE_CITY,
  WEBSITE_NAME,
  WEBSITE_STREET_NUMBER,
  WEBSITE_ZIPCODE,
} from "../../constants/CompanyConstants";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { device } from "../../utils/breakpoints";
import { privacy } from "../../urls";
import { Link } from "react-router-dom";
import { colors } from "../../utils/theme";

const FooterBottom = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <Wrapper>
        <TextBold>{WEBSITE_NAME}</TextBold>
        <a href={GOOGLE_MAPS_LINK} target="_blank" rel="noopener noreferrer">
          {WEBSITE_STREET_NUMBER}, {WEBSITE_ZIPCODE} {WEBSITE_CITY}
        </a>
        <Link to={privacy}>{t("privacy_policy")}</Link>
        <a href={t("documents.terms")} download>
          {t("terms_and_conditions")}
        </a>
        <PoweredByContainer>
          <p>{t("powered_by")}</p>
          <a
            href="https://digitalkiss.nl"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={DIGITAL_KISS_LOGO} alt={""} />
          </a>
        </PoweredByContainer>
        <SocialsContainer>
          {SOCIAL_MEDIA.map((item, key) => {
            return (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                key={key}
                aria-label={t("go_to_social_media", { name: item.name })}
              >
                <i className={item.icon} />
              </a>
            );
          })}
        </SocialsContainer>
      </Wrapper>
    </StyledContainer>
  );
};

const Text = styled.p``;

const TextBold = styled(Text)`
  font-weight: 600;
`;

const StyledContainer = styled(Container)`
  @media only screen and ${device.xl} {
    max-width: 1400px;
  }
`;

const Wrapper = styled.div`
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1400px;
  margin-inline: auto;

  a,
  p,
  span {
    color: white;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
  }

  a:hover {
    opacity: 0.8;
  }

  @media only screen and ${device.xl} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SocialsContainer = styled.div`
  display: flex;
  gap: 1.5rem;

  i {
    color: ${colors.secondary};
    font-size: 28px;
  }
`;

const PoweredByContainer = styled.div`
  display: flex;
  gap: 6px;

  a:hover {
    opacity: 1;
  }

  img {
    width: 100px;
    margin-bottom: 2px;
  }
`;

export default FooterBottom;

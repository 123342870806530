import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/Layout";
import { useTranslation } from "react-i18next";
import { WEBSITE_NAME } from "../../../constants/CompanyConstants";
import { Helmet } from "react-helmet";
import { BreadcrumbStructuredData } from "../../../components/structured-data/BreadcrumbStructuredData";
import {
  BREADCRUMB_HOME,
  BREADCRUMB_RECIPES,
} from "../../../constants/BreadcrumbConstants";
import styled from "styled-components";
import { colors } from "../../../utils/theme";
import { Breadcrumb, Col, Divider, Row } from "antd";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Loader } from "../../../components/loader/Loader";
import ShopProducts from "../../../wrappers/product/ShopProducts";
import BreadcrumbContainer from "../../../wrappers/breadcrumb/BreadcrumbContainer";
import urls from "../../../urls";
import { LeftOutlined } from "@ant-design/icons";
import { device } from "../../../utils/breakpoints";
import ReactPlayer from "react-player";
import useIsMobile from "../../../components/hooks/useIsMobile";
import { PageTitle } from "../../../styles/SharedStyles";

const RecipesDetail = () => {
  const { t } = useTranslation();
  const { url } = useParams();
  const isMobile = useIsMobile();
  const recipes = useSelector(
    (state) => state.firestoreReducer.ordered.recipes
  );
  const products = useSelector(
    (state) => state.firestoreReducer.ordered.products
  );
  const [recipe, setRecipe] = useState(null);
  const [recipeProducts, setRecipeProducts] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!recipe && !!recipes) {
      setLoading(true);
      const recipe = recipes.find((recipe) => recipe.url === url);
      setRecipe(recipe);
      setLoading(false);

      // Get products in recipe
      if (!!recipe && !!recipe.products && recipe.products.length > 0) {
        const recipeProducts = recipe.products.map((id) => {
          return products.find((p) => p.id === id);
        });
        setRecipeProducts(recipeProducts);
      }
    }
  }, [recipes]);

  return (
    <Fragment>
      {!!recipe && (
        <Helmet>
          <title>
            {recipe.title} · {t("recipes")} · {WEBSITE_NAME}
          </title>
          {BreadcrumbStructuredData([
            BREADCRUMB_HOME,
            BREADCRUMB_RECIPES,
            {
              name: recipe.title,
              url:
                process.env.REACT_APP_WEBSITE_URL +
                urls.recipes +
                "/" +
                recipe.url,
            },
          ])}
        </Helmet>
      )}
      <Layout headerTop="visible">
        {/* breadcrumb */}
        <BreadcrumbContainer>
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link
                to={urls.recipes}
                style={{ display: "flex", alignItems: "center" }}
              >
                <LeftOutlined style={{ marginRight: 6 }} />
                {t("all_recipes")}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </BreadcrumbContainer>
        <PageWrapper className={"container"}>
          {!loading && !!recipe ? (
            <>
              <Recipe>
                <img
                  src={recipe.image}
                  alt={!!recipe.imageAlt ? recipe.imageAlt : recipe.title}
                />
                <div className={"information"}>
                  <PageTitle style={{ color: colors.headingDark }}>
                    {recipe.title}
                  </PageTitle>
                  <p className={"description"}>{recipe.description}</p>
                  <div className={"d-flex"}>
                    <p style={{ marginRight: 12 }}>
                      🕓 {t("minutes_amount", { amount: recipe.minutes })}
                    </p>
                    <p>
                      🍽️ {t("servings_amount", { amount: recipe.servings })}
                    </p>
                  </div>
                  <p className={"tag"}>{recipe.tag}</p>
                </div>
                <Divider />
                <Row gutter={[32, 32]} style={{ marginTop: "3rem" }}>
                  <Col xs={24} md={8}>
                    <div className={"ingredients"}>
                      <h3>{t("ingredients")}</h3>
                      {recipe.ingredients.map((ingredient, index) => (
                        <div key={index}>
                          <p className={"unit"}>{`${ingredient.amount} ${t(
                            !!ingredient.unit ? ingredient.unit + "_short" : ""
                          )}`}</p>
                          <p className={"name"}>{ingredient.product}</p>
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col xs={24} md={16}>
                    <div className={"preparation"}>
                      <h3>{t("preparation_method")}</h3>
                      <Divider />
                      <div className={"steps"}>
                        {recipe.steps.map((step, index) => (
                          <div key={index}>
                            <p className={"number"}>{index + 1}</p>
                            <p className={"text"}>{step}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                    {!!recipe.videoUrl && (
                      <VideoWrapper>
                        <ReactPlayer
                          url={recipe.videoUrl}
                          width={isMobile ? "100%" : 640}
                          controls
                        />
                      </VideoWrapper>
                    )}
                  </Col>
                </Row>
              </Recipe>
              <Divider style={{ marginTop: "4rem", marginBottom: "4rem" }} />
              {!!recipeProducts && (
                <ProductsWrapper>
                  <h2>{t("products_in_recipe")}</h2>
                  <ShopProducts products={recipeProducts} />
                </ProductsWrapper>
              )}
            </>
          ) : (
            <Loader />
          )}
        </PageWrapper>
      </Layout>
    </Fragment>
  );
};

const VideoWrapper = styled.div`
  margin-top: 2rem;

  iframe {
    box-shadow: 0 6px 20px rgba(84, 84, 84, 0.15);
    border-radius: 10px;
  }

  @media ${device.lg} {
    margin-top: 2rem;
  }
`;

const ProductsWrapper = styled.div``;

const Recipe = styled.div`
  position: relative;

  img {
    border-radius: 6px;
    margin-bottom: 3rem;
    box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: auto;
  }

  h3 {
    color: ${colors.primaryDark};
    font-weight: 600;
  }

  .ingredients {
    padding: 1.5rem;
    border-radius: 25px;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);

    div {
      display: flex;

      .unit {
        width: 25%;
        font-weight: 600;
      }

      .name {
        width: 75%;
      }
    }

    h3 {
      margin-bottom: 1rem;
    }
  }

  .preparation {
    .steps {
      div {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;

        .number {
          min-width: 50px;
          min-height: 50px;
          font-size: 24px;
          border: 2px solid ${colors.primaryDark};
          border-radius: 50%;
          text-align: center;
          padding: 12px;
          margin-right: 1rem;
          margin-bottom: 0;
          color: ${colors.primaryDark};
        }

        .text {
          font-size: 16px;
        }
      }
    }
  }

  .information {
    display: flex;
    flex-direction: column;

    p {
      white-space: pre-line;
      font-size: 16px;

      &.description {
        font-size: 18px;
        line-height: 1.75;
      }

      &.tag {
        background-color: ${colors.featuredTag};
        width: fit-content;
        color: white;
        border-radius: 20px;
        padding: 4px 12px;
        font-weight: 500;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

const PageWrapper = styled.div`
  padding-bottom: 4rem;
`;

export default RecipesDetail;

import React from "react";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";
import { colors } from "../../utils/theme";

const BannerTitle = ({ children, dark, ...props }) => {
  return (
    <StyledTitle {...props} dark={dark ? 1 : 0}>
      {children}
    </StyledTitle>
  );
};

const StyledTitle = styled.h2`
  font-family: "Krusyida Script", cursive;
  font-size: 2rem;
  text-align: ${({ start }) => (start ? "start" : "center")};
  color: ${({ dark }) => (dark ? colors.textDarkMode : colors.headingDark)};

  @media only screen and ${device.md} {
    font-size: 2.5rem;
  }
`;

export default BannerTitle;
